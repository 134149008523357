.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.teeth_container {
  margin-left: 1em;
  margin-right: 1em;
}

.arch_container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
}

.tooth_container {
  flex-basis: 6.25%;
  overflow: hidden;
  font-size: 85%;
  font-weight: bold;
  height: 4em;
  border-style: solid;
  margin: 0.1em;
  border-color: #717272;
  background-color: #555555;
  border-radius: 0.25em;
}

.ratio_wrapper {
  max-width: 100%;
}

.title_font {
  font-family: "Hina Mincho", serif;
  font-family: "Dawning of a New Day", cursive;
  font-family: "Comfortaa", cursive;
  font-size: 3em;
  color: #282c34;
  color: #ffffff;
  text-decoration: none;
}

.navbar_container {
  width: 100%;
  max-height: 3.75em;
  margin-bottom: 1em;
  display: flex;
  border: solid;
  border-width: 0.1em;
  background-color: #777777;
  border-color: #777777;
}

.title_container {
  width: auto;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 2em;
  color: #ffffff;
}

.nav_item_container {
  justify-content: flex-start;
  display: flex;
  flex-grow: 100;
  align-items: center;
  font-family: "roboto";
  padding-right: 0.75em;
}

.nav_item {
  padding: 0.5em;
  text-align: center;
  display: inline-block;
  margin-bottom: 0.25em;
  margin-right: 0.75em;
  margin-top: auto;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  font-weight: 1em;
  border-radius: 0.2em;
  background-color: #666666;
}

.hamburger_container {
  width: 5em;
}

.account {
  margin-left: auto;
}

.tooth_title_container {
  text-align: center;
  vertical-align: center;
  font-family: "roboto";
  width: 100%;
  color: #ffffff;
  cursor: default;
}

.button:hover {
  background-color: #444444;
  cursor: default;
  user-select: none;
}

.tooth_container:hover {
  background-color: #333333;
}

.procedures_container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: 1em;
  margin-right: 1em;
  padding-top: 1em;
}

.procedure_container:first-child {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em;
}

.procedure_container:last-child {
  border-bottom: solid;
}

.procedures_navbar_container {
  height: 2.5em;
  border: solid;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding-left: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;

  border-radius: 0.2em;
  background-color: #777777;
  border-color: #777777;
}

.procedures_filter_container {
  display: flex;
  z-index: 1;
  margin-top: 0.25em;
}

.procedures_filter_button {
  padding: 0.5em;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: 0.2em;
  margin-left: 0.3em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  font-weight: 1em;
  border-radius: 0.2em;
  background-color: #666666;
}

.procedures_sort_button {
  padding: 0.5em;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: 0.2em;
  margin-right: 0.3em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  font-weight: 1em;
  border-radius: 0.2em;
  background-color: #666666;
}

.sort_direction_button {
  padding: 0.25em;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: 0.2em;
  margin-right: 0.3em;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  font-weight: em;
  border-radius: 0.2em;
  background-color: #666666;
}

.procedures_filter_dropdown_option {
  padding: 1em;
  text-align: left;
  display: inline-block;
  margin-top: auto;
  margin-bottom: 0em;
  margin-right: 0.3em;
  margin-left: 0.3em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8em;
  font-weight: 1em;
  background-color: #666666;
  max-height: 3em;
  cursor: default;
  user-select: none;
}

.procedures_filter_dropdown_option:hover {
}

.procedures_filter_dropdown_option:first-child {
  border-top-right-radius: 0.25em;
  border-top-left-radius: 0.25em;
}

.procedures_filter_dropdown_option:last-child {
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.active_tooth {
  background-color: #333333;
}

.active_filter {
  background-color: #444444;
}

.procedures_filter_dropdown_container {
  display: flex;
  flex-direction: column;
  margin-top: 0.1em;
}

.procedure_container {
  padding: 0.2em;
  display: flex;
  justify-content: flex-end;
  border: solid;
  border-radius: 0em;
  border-width: 0.05em;
  border-color: #666666;
  border-bottom: none;
}

.procedure_item_container {
  padding: 0.5em;
  text-align: center;
  align-items: center;
  display: flex;
  margin-top: auto;
  margin: 0.15em;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  font-weight: 1em;
  border-radius: 0.2em;
  border-color: #666666;
  background-color: #666666;
}

.file_option {
  padding: 1em;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  font-weight: 1em;
  background-color: #666666;
  border-top: 0.1em solid #555555;
}

.procedure_item_container:nth-child(2) {
  margin-right: auto;
  border: none;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

html,
body {
  height: 100%;
  min-height: 100%;
  background-color: #333333;
  color: #ffffff;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
}

.white_input {
  color: #111111;
  margin-top: 0.25em;
  margin-bottom: 1.5em;
}

input.signin_submit {
  background-color: #666666;
  color: #ffffff;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
}

.signin_container {
  min-width: 12em;
  min-height: 12em;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid;
  border-radius: 0.2em;
  border-color: #444444;
  background-color: #777777;
  padding: 1em;
  margin-bottom: 5em;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
}

.upload_container {
  min-width: 12em;
  min-height: 12em;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid;
  border-radius: 0.2em;
  border-color: #444444;
  background-color: #777777;
  padding: 1em;
  margin-bottom: 5em;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5em;
}

/* The Close Button */
.signin_close_container {
  color: #aaa;
  margin-left: auto;
  font-size: 1em;
  font-weight: bold;
}

.signin_close_container:hover,
.signin_close_container:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.nav_back_button {
  z-index: 2;
}

label {
  display: block;
  font-size: 0.7em;
}

input.todoclass {
  margin-top: 0.5em;
  font-family: "Montserrat", sans-serif;
}

input[type="submit"].todoclass {
  font-size: 0.75em;
  margin-top: 2em;
}

.signin_title_container {
  margin-bottom: 1em;
  text-align: center;
}

.password_message .email_message {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
  text-align: left;
  font-size: 0.75em;
}

.explanation {
  font-size: 0.75em;
  color: #ff6961;
}

.insights_container {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 1em;
  margin-right: 1em;
  cursor: default;
  user-select: none;
}

.insight_container {
  font-family: "Montserrat", sans-serif;
  border: solid;
  border-color: #717272;
  border-radius: 0.2em;

  border-width: 0.25em;
  display: flex;
  flex-direction: column;
  margin: 0.33em;

  width: 28em;
}

.insight_title_container {
  padding: 0.5em;
  text-align: center;
  display: inline-block;
  margin-bottom: 0.25em;
  font-size: 1.3em;
  font-weight: 1em;
  background-color: #777777;
  cursor: default;
  user-select: none;
}

.insight_body_container {
  flex-grow: 100;
  font-size: 0.8em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 1em;
}

.insight_footer_container {
  padding: 0.6em;
  text-align: center;
  display: inline-block;
  margin-top: auto;
  font-size: 1em;
  font-weight: 1em;
  background-color: #666666;
  margin-top: auto;
}

.avg_grade_container {
  padding: 0.5em;
  text-align: center;
  display: inline-block;
  margin-top: auto;
  margin-bottom: 0.2em;
  margin-left: 0.6em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75em;
  font-weight: 1em;
  border-radius: 0.2em;
  border-color: #e9d9c8;
  background-color: #f8e9d9;
}

.insight_explanation {
  line-height: 2em;
  word-wrap: break-word;
  font-size: 1.14em;
  max-width: 20em;
}

.insight_button {
  padding: 0.5em;
  text-align: center;
  display: inline-block;
  margin-top: auto;
  margin-bottom: 0.25em;
  font-size: 1.3em;
  font-weight: 1em;
  border-radius: 0.2em;
  background-color: #666666;
  margin-top: 0;
}

.insight_button:hover {
  background-color: #f8e9d9;
}

.insight_filter_row_container {
  display: flex;
  flex-wrap: wrap;
}

.upcoming_title_container {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.6em;
  justify-content: center;
}

.upcoming_title_container > .procedure_item_container_insight:last-child {
  margin-left: auto;
}

.insight_filter_row_container_upcoming_evaluations {
  display: flex;
  flex-wrap: wrap;
  border: solid;
  border-radius: 0.3em;
  border-width: 0.15em;
  border-color: #777777;
  padding: 0.5em;
}

.procedure_item_container_insight {
  border-radius: 0.2em;
  border-width: 0.1em;
  padding: 0.5em;
  text-align: center;
  display: flex;
  margin-top: auto;
  margin-bottom: 0.2em;
  margin-right: 0.6em;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
  font-weight: 1em;
  background-color: #666666;
}

.dual_element_container {
  border-radius: 0.2em;
  border-width: 0.1em;
  text-align: center;
  display: flex;
  margin-top: auto;
  margin-bottom: 0.2em;
  margin-right: 0.6em;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
  font-weight: 1em;
  background-color: #666666;
}

.procedure_item_insight {
  border-radius: 0.2em;
  border-width: 0.1em;
  padding: 0.5em;
  text-align: center;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 1em;
  background-color: #666666;
}

.procedure_item_insight:last-child {
  border-top-left-radius: 0em;
  border-bottom-left-radius: 0em;
}

.procedure_item_insight:first-child {
  border-top-right-radius: 0em;
  border-bottom-right-radius: 0em;
  border-right: solid;
  border-width: 0.15em;
  border-color: #555555;
}

.create_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
  cursor: default;
  user-select: none;
}

.criteria_container {
  height: 100%;
  border: none;
  border-radius: 0.5em;
  border-color: #999999;
  border-bottom: none;
  display: flex;
  flex-direction: column;
}

.create_nav_container {
  background-color: #777777;
  margin-right: 2em;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 0.5em;
}

.criterion_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: solid;
  border-bottom: none;
  border-color: #cccccc;
  padding: 0.2em;
}

.criterion_container:first-child {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.criterion_container:last-child {
  border-bottom: solid;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.criterion_item_container {
  border-radius: 0.2em;
  border-width: 0.1em;

  display: flex;
  justify-content: center;
  text-align: center;

  max-width: 10em;

  margin-left: 0.2em;
  padding: 0.5em;

  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  font-weight: 1em;

  background-color: #666666;
}

.criterion_item_container:last-child {
}

.rubric {
  max-width: 10em;
  min-height: 6em;
  min-width: 10em;
  margin: 0.1em;
  user-select: text;
}

input {
  color: #ffffff;
  display: flex;
  justify-content: left;
}

textarea {
  color: #ffffff;
  display: flex;
  justify-content: left;
}

.eval_item_container {
  padding: 0.75em;
  border: solid;
  border-color: #aaaaaa;
  border-right: none;
}

.eval_item_container:hover {
  background-color: #444444;
}

.eval_item_container:last-child {
  border-right: solid;
  border-color: #aaaaaa;

  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.eval_item_container:first-child {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.rubric_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eval_container {
  height: 3em;
}

.grade_container {
  height: 3em;
  min-width: 5em;
  align-items: center;
  margin-right: 1.5em;
}

.self_eval_container {
  height: 100%;
}

.threeD_container {
  height: 100%;
  width: calc(100%);
  display: flex;
  flex-direction: row;
}

.camera_container {
  height: 100%;
  width: calc(100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.threeD_navigation_container {
  height: 100%;
  width: 20em;
  border: solid;
  margin-left: 1em;
  border-radius: 0.2em;
  border-color: #777777;
  display: flex;
  flex-direction: column;
}

.camera_navigation_container {
  height: 100%;
  width: 14.5em;
  border: solid;
  margin-left: 1em;
  border-radius: 0.2em;
  border-color: #777777;
  display: flex;
  flex-direction: column;
}

.file_option:first-child {
  border: none;
}

.canvas_div {
  width: 80%;
}

.photo_container {
  width: calc(95% - 20em);
  height: 95%;
  border: 0.1em solid #999999;
  border-radius: 0.2em;
  margin: 1em;
  margin-right: 4em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.active_eval {
  background-color: #444444;
}

.create_item_container {
  display: flex;
  padding: 0.5em;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: auto;
  margin: 0.15em;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  font-weight: 1em;
  border-radius: 0.2em;
  border-color: #666666;
  background-color: #666666;
}

#my-awesome-dropzone {
  flex-grow: 1;
  display: flex;
}

.dropzone {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed;
  border-color: #999999;
  margin: 0.5em;
  padding: 0.5em;
  font-family: "Montserrat", sans-serif;
}
